<template>
  <b-dropdown
    :dropup="dropup"
    variant="primary"
    class="locale-link"
  >
    <template #button-content>
      {{ localeNamesByCurrent[currentLocale.locale] }}
      <feather-icon :icon="dropup ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      href="#"
      @click.prevent="changeLocale(localeObj.locale)"
    >
      {{ localeNamesByCurrent[localeObj.locale] }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { localize as localizeVeeValidate } from 'vee-validate'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    dropup: {
      type: Boolean,
      default: true,
    },
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },

    localeNamesByCurrent() {
      return ({
        pt: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },

        en: {
          pt: 'Portuguese',
          en: 'English',
          es: 'Spanish',
          br: 'Portuguese (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },

        es: {
          pt: 'Portugués',
          en: 'Inglés',
          es: 'Español',
          br: 'Portugués (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },

        br: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },

        ko: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },

        de: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },

        pl: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },

        zh: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },

        fr: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },

        it: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },

        ar: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },
        hi: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },
        af: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },
        ja: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },
        ru: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol',
          br: 'Português (BR)',
          ko: 'Korea',
          de: 'Deutsch',
          pl: 'Polska',
          zh: 'Chinese',
          it: 'Italiano',
          hi: 'Hindu',
          ar: 'Arabic',
          fr: 'French',
          af: 'Africane',
          ja: 'Japanese',
          ru: 'Russo',
        },
      })[this.$i18n.locale]
    },
  },
  setup() {
    const locales = [
      {
        locale: 'en',
      },
      {
        locale: 'es',
      },
      {
        locale: 'pt',
      },
      {
        locale: 'br',
      },
      {
        locale: 'ko',
      },
      {
        locale: 'de',
      },
      {
        locale: 'pl',
      },
      {
        locale: 'zh',
      },
      {
        locale: 'fr',
      },
      {
        locale: 'it',
      },
      {
        locale: 'hi',
      },
      {
        locale: 'ar',
      },
      {
        locale: 'af',
      },
      {
        locale: 'ja',
      },
      {
        locale: 'ru',
      },
    ]

    return {
      locales,
    }
  },

  methods: {
    changeLocale(key) {
      this.$i18n.locale = key
      localizeVeeValidate(key)
    },
  },
}
</script>

<style>
.locale-link .dropup {
    margin: 0!important;
}
.locale-link .btn {
    background-color: unset!important;
    color: #5E5873!important;
    border: 0;
    padding: 0;
    border-bottom: 0!important;
    box-shadow: unset!important;
}
</style>
